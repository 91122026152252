import { css } from '@emotion/css';
import { colors, measurements, styleHelpers } from '@imtbl/design-system';

export const Container = css`
  display: flex;
  border-radius: ${measurements.standardBorderRadius};
  background: ${colors.dark[700]};
  border: 1px solid ${colors.dark[300]};
  overflow: hidden;
  position: relative;
  width: 100%;
  transition: var(--animations_standardTransitionDuration)
    var(--animations_standardTransitionEasing);
  transition-property: all;
  padding: 0;
  align-items: stretch;
`;

export const Container___onHover = css`
  &:hover {
    cursor: pointer;
    border-color: var(--colors_light_100);
    background: var(--colors_dark_500);
    box-shadow: 0px 20px 40px rgba(var(--colors_black-rgb), 0.6);
    z-index: 1;
  }
`;

export const Container___disabled = css`
  opacity: 0.6;
`;

export const IconOrImage = css`
  width: 80px;
  min-height: 80px;
  flex-shrink: 0;
  position: relative;
  border-right: 1px solid ${colors.dark[300]};
`;

export const IconOrImage_ratio = css`
  width: 100%;
  padding-bottom: 100%;
`;

export const IconOrImage_img = css`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center;
`;

export const IconOrImage_icon = css`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`;

export const PrimaryText = css`
  ${styleHelpers.lineClamp(2)}
  color: var(--colors_light_100);
`;

export const SecondaryText = css`
  ${styleHelpers.lineClamp(2)}
  color: var(--colors_light_300);
`;
