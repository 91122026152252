import { cx } from '@emotion/css';
import {
  BaseComponentPropTypes,
  colors,
  CustomUiText,
  FlexLayout,
  Icon,
  layoutHelpers,
  measurements,
  OuterSpace,
  ResponsiveAndCachedImage,
  SimpleText,
} from '@imtbl/design-system';
import React, { DetailedHTMLProps, HTMLAttributes, ReactNode } from 'react';
import { IMX_RESIZER_SERVICE_ENV_URL } from 'utils/constants';

import * as styles from './ImxHorizontalAssetTile.styles';

export interface ImxHorizontalAssetTilePropTypes
  extends BaseComponentPropTypes<HTMLDivElement>,
    DetailedHTMLProps<HTMLAttributes<HTMLDivElement>, HTMLDivElement> {
  primaryText: ReactNode;
  secondaryText?: ReactNode;
  assetImage?: string;
  assetIconLigature?: string;
}

export function ImxHorizontalAssetTile({
  className,
  domRef,
  primaryText,
  secondaryText,
  assetImage,
  assetIconLigature,
  testId,
  ...props
}: ImxHorizontalAssetTilePropTypes): JSX.Element {
  return (
    <div
      className={cx(styles.Container, className)}
      data-testid={testId}
      ref={domRef}
      {...props}
    >
      <div className={styles.IconOrImage}>
        {assetImage ? (
          <ResponsiveAndCachedImage
            altText="Asset thumbnail"
            imageUrl={assetImage}
            relativeImageSizeInLayout="100px"
            className={styles.IconOrImage_img}
            testId={`${testId}__responsiveImg`}
            responsiveSizes={measurements.standardIconImageSizes}
            imageResizeServiceUrl={IMX_RESIZER_SERVICE_ENV_URL}
          />
        ) : assetIconLigature ? (
          <Icon
            ligature={assetIconLigature}
            className={styles.IconOrImage_icon}
            iconSize="55px"
            fillColor={colors.light[100]}
            testId={`${testId}__icon`}
          />
        ) : null}
      </div>

      <FlexLayout
        flexGrow={1}
        flexDirection="column"
        justifyContent="center"
        padding={layoutHelpers.gridUnits(3)}
        paddingBottom={layoutHelpers.gridUnits(4)}
        paddingLeft={layoutHelpers.gridUnits(6)}
      >
        {typeof primaryText === 'string' || typeof primaryText === 'number' ? (
          <CustomUiText
            fontSize="small"
            fontWeight="bold"
            className={cx(styles.PrimaryText)}
            testId={`${testId}__primaryText`}
          >
            {primaryText}
          </CustomUiText>
        ) : (
          primaryText
        )}

        <OuterSpace top={measurements.SpacingTeeShirtAmounts['x-small']}>
          {typeof secondaryText === 'string' ||
          typeof secondaryText === 'number' ? (
            <SimpleText
              fontSize="tag"
              className={cx(styles.SecondaryText)}
              testId={`${testId}__secondaryText`}
            >
              {secondaryText}
            </SimpleText>
          ) : (
            secondaryText
          )}
        </OuterSpace>
      </FlexLayout>
    </div>
  );
}
